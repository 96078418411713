import { Component, OnInit, OnDestroy, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { IoServer, IoServersService } from "../../../components/io-servers/io-servers.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "src/app/services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { KeyMap } from "src/app/models/shared";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { IoServerStatusColumnComponent } from "./io-server-status-column/io-server-status-column.component";
import { IoServerTunnelsColumnComponent } from "./io-server-tunnels-column/io-server-tunnels-column.component";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-io-server-list",
    templateUrl: "./io-server-list.component.html"
})
export class IoServerListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    ioServers: IoServer[] = [];
    filteredIoServers: IoServer[];

    urls = Constants.urls;

    private ioServersSubscription: Subscription;
    private ioServersRefreshSubscription: Subscription;

    private ioServersBS$ = new BehaviorSubject<IoServer[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("DNS_PREFIX"),
            columnDef: "dns_prefix",
            width: 240,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                row => row.dns_prefix,
                row => row.dns_prefix,
                () => true
            ),
            sortBy: (row: IoServer): string => row?.dns_prefix,
            textValue: (row: IoServer): string => row?.dns_prefix
        },
        {
            header: this.translate.instant("ACCOUNT"),
            columnDef: "account",
            width: 240,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                row => (row.Customer ? `${row.Customer?.name} (${row.Customer?.dns_prefix})` : ""),
                row => (row.Customer ? `${row.Customer?.name} (${row.Customer?.dns_prefix})` : ""),
                () => true
            ),
            sortBy: (row: IoServer): string =>
                row.Customer ? `${row.Customer?.name} (${row.Customer?.dns_prefix})` : "",
            textValue: (row: IoServer): string =>
                row.Customer ? `${row.Customer?.name} (${row.Customer?.dns_prefix})` : ""
        },
        // Status
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: IoServerStatusColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<IoServerStatusColumnComponent>, row: IoServer) => {
                const componentInstance = compRef.instance;
                componentInstance.ioServer = row;
            },
            sortBy: (row: IoServer) => row._frontData?.sortableStatus
        },
        // Uptime
        {
            header: this.translate.instant("UPTIME"),
            columnDef: "uptime",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                (row: KeyMap<IoServer>) => (row.status?.uptime ? this.uptime.transform(row.status?.uptime) : "-"),
                (row: KeyMap<IoServer>) => (row.status?.uptime ? this.uptime.transform(row.status?.uptime) : "-"),
                (row: KeyMap<IoServer>) => !!row?.status?.uptime
            ),
            sortBy: (row: KeyMap<IoServer>) => (row.status?.uptime ? this.uptime.transform(row.status?.uptime) : "-"),
            textValue: (row: KeyMap<IoServer>) => (row.status?.uptime ? this.uptime.transform(row.status?.uptime) : "-")
        },
        // Type
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                row => row.instance_type,
                row => row.instance_type,
                () => true
            ),
            sortBy: (row: IoServer): string => row?.instance_type,
            textValue: (row: IoServer): string => row?.instance_type
        },
        // Region
        {
            header: this.translate.instant("REGION"),
            columnDef: "region",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                row => row.region ?? "us-east-1 (OLD)",
                row => row.region ?? "us-east-1 (OLD)",
                () => true
            ),
            sortBy: (row: IoServer): string => row?.region ?? "us-east-1 (OLD)",
            textValue: (row: IoServer): string => row?.region ?? "us-east-1 (OLD)"
        },
        // AWS_AMI
        {
            header: this.translate.instant("AWS_AMI"),
            columnDef: "AWS_AMI",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<IoServer>>(
                row => row.ami,
                row => row.ami,
                () => true
            ),
            sortBy: (row: IoServer): string => row?.ami,
            textValue: (row: IoServer): string => row?.ami
        },
        // Tunnels
        {
            header: this.translate.instant("TUNNELS"),
            columnDef: "tunnels",
            width: 120,
            visible: true,
            component: IoServerTunnelsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<IoServerTunnelsColumnComponent>, row: IoServer) => {
                const componentInstance = compRef.instance;
                componentInstance.ioServer = row;
            },
            sortBy: (row: IoServer) => row._frontData?.sortableTunnels
        },
        // Actions
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<IoServer, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.editIoServer(row),
                deleteRef: row => this.deleteIoServer(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private ios: IoServersService,
        public sharedService: SharedService,
        private userService: UsersService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService,
        private translate: TranslateService,
        private uptime: UptimePipe
    ) {
        // Set Title
        this.titleService.setTitle("IO_SERVERS", "");
    }

    ngOnInit() {
        this.loading = true;

        this.ioServersSubscription = this.ios.ioServers.subscribe(ioServers => {
            this.ioServers = ioServers;
            if (this.ioServers) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // Start Auto Refresh
        this.startIoServersRefresh();
    }

    ngOnDestroy() {
        this.ioServersSubscription.unsubscribe();
        this.stopIoServersRefresh();
    }

    editIoServer(ioServer: IoServer): void {
        this.router.navigate([Constants.urls.io_servers, ioServer.id, "edit"]);
    }

    async deleteIoServer(ioServer: IoServer) {
        await this.modalService.confirm(
            "DELETE",
            "IO_SERVER",
            async () => {
                const id = ioServer.id;
                const result = await this.ios.deleteIoServer(ioServer);
                if (result) this.mixpanelService.sendEvent("delete io server", { id });
            },
            ioServer.dns_prefix
        );
    }

    async refresh() {
        this.refreshing = true;
        const ioServersRefresh = this.ios.refreshIoServers(true).toPromise();
        await Promise.all([ioServersRefresh]);
        this.refreshing = false;
    }

    startIoServersRefresh() {
        this.ioServersRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopIoServersRefresh() {
        this.ioServersRefreshSubscription.unsubscribe();
    }

    get ioServers$() {
        return this.ioServersBS$.asObservable();
    }

    private prepTableData() {
        if (this.ioServers) {
            this.ioServersBS$.next([...this.ioServers]);
        }
    }
}
